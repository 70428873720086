





























import VueUeditorWrap from "vue-ueditor-wrap";
import { articleTypes, remoteServiceBaseUrl } from "@/libs/appconst";

import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    VueUeditorWrap
  }
})
export default class ArticleEdit extends Vue {
  pagerHeader: any = {
    title: "添加/修改文章",
    desc: "添加/修改文章",
    breadcrumb: ["文章", "文章编辑"]
  };
  config: any = {
    // 编辑器不自动被内容撑高
    autoHeightEnabled: false,
    // 初始容器高度
    initialFrameHeight: 350,
    // 初始容器宽度
    initialFrameWidth: 1000,
    // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
    serverUrl: `${remoteServiceBaseUrl}/api/UEditor/do`,
    // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
    UEDITOR_HOME_URL: process.env.BASE_URL + "UEditor/"
  };
  loading: boolean = false;
  categories: any[] = [];
  articleModel: any = {
    id: 0,
    title: "",
    categoryId: "",
    content: ""
  };
  rules: any = {
    title: [{ required: true, message: "请输入文章标题", trigger: "blur" }],
    categoryId: [
      {
        required: true,
        message: "请选择类型",
        trigger: "change"
      }
    ],
    content: [{ required: true, message: "请输入内容", trigger: "blur" }]
  };

  async created() {
    this.loading = true;
    await this.readerCategory();
    await this.getData();

    this.loading = false;
  }
  async activated() {
    if (this.articleModel.id != this.$route.query.id) await this.getData();
  }
  async readerCategory() {
    try {
      var res = await this.$ajax.get("/api/services/app/Category/GetAll", {
        params: {
          type: 3
        }
      });
      this.categories = res.data.result;
    } catch (error) {}
  }
  async getData() {
    let id = this.$route.query.id;
    if (id && parseInt(id as string) > 0) {
      try {
        let res = await this.$ajax.get("/api/services/app/Article/Get", {
          params: {
            id
          }
        });
        this.loading = false;
        if (res.data.success) {
          this.articleModel.id = res.data.result.id;
          this.articleModel.title = res.data.result.title;
          this.articleModel.categoryId = res.data.result.categoryId;
          this.articleModel.content = res.data.result.content;
        } else {
          this.$alert(res.data.error.message, "");
        }
      } catch {
        this.$alert("加载数据出错", "");
      }
    } else {
      this.articleModel.id = 0;
      this.articleModel.title = "";
      this.articleModel.categoryId = "";
      this.articleModel.content = "";
    }
  }
  submitForm(formName: string) {
    (this.$refs[formName] as any).validate(async (valid: boolean) => {
      if (valid) {
        this.loading = true;
        let res;
        try {
          if (!this.articleModel.id) {
            res = await this.$ajax.post(
              "/api/services/app/Article/Create",
              this.articleModel
            );

            if (res.data.success) {
              this.$notify({
                title: "成功",
                message: "修改成功！",
                type: "success"
              });
              this.closeCurrTabAndGoToUrl("");
            }
          } else {
            res = await this.$ajax.put(
              "/api/services/app/Article/Update",
              this.articleModel
            );

            if (res.data.success) {
              this.$notify({
                title: "成功",
                message: "修改成功！",
                type: "success"
              });
              this.closeCurrTabAndGoToUrl("");
            }
          }
        } catch {}
        this.loading = false;
      }
    });
  }
}
